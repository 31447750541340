var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticStyle: {
      "min-height": "85vh"
    }
  }, [_c('div', {
    staticClass: "sso"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/logo-min.png")
    }
  }), _c('h2', [_vm._v(" 请点击前往站点 "), _c('a', {
    attrs: {
      "href": "http://sso.exrick.cn",
      "target": "_blank"
    }
  }, [_vm._v("sso.exrick.cn")]), _vm._v(" 进行测试 ")]), _c('a', {
    attrs: {
      "href": "http://sso.exrick.cn",
      "target": "_blank"
    }
  }, [_c('Button', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "type": "primary"
    }
  }, [_vm._v("立即前往另一站点")])], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };